.card {
    background-color: light-dark(var(--mantine-color-white), var(--mantine-color-dark-7));
  }
  
  .section {
    border-bottom: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
    padding-left: var(--mantine-spacing-md);
    padding-right: var(--mantine-spacing-md);
    padding-bottom: var(--mantine-spacing-md);
  }
  
  .like {
    color: var(--mantine-color-red-6);
    width: rem(20px);
    height: rem(20px);
  }
  
  .label {
    text-transform: uppercase;
    font-size: var(--mantine-font-size-xs);
    font-weight: 700;
  }

  .button {
    background-color:rgb(253, 166, 4);
  }