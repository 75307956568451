.card {
  height: rem(400px);
  background-size: cover;
  background-position: center;
  
}

.content {
  position: absolute;
  inset: 0;
  padding: var(--mantine-spacing-xl);
  z-index: 1;
}

.action {
  position:absolute;
  bottom: var(--mantine-spacing-xl);
  right: var(--mantine-spacing-xl);
}

.title {
  color: var(--mantine-color-white);
  margin-bottom: calc(var(--mantine-spacing-xs) / 2);
}

.description {
  color: var(--mantine-color-white);
  max-width: rem(220px);
}

.overlay {
  background-color: transparent;
  background-image: linear-gradient(
    105deg,
    var(--mantine-color-black) 20%,
    #312f2f 50%,
    var(--mantine-color-gray-4) 100%
  );
}