.card {
    background-color: var(--mantine-color-body);
  }
  
  .imageSection {
    padding: var(--mantine-spacing-md);
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
  }
  
  .label {
    margin-bottom: var(--mantine-spacing-xs);
    line-height: 1;
    font-weight: 700;
    font-size: var(--mantine-font-size-xs);
    letter-spacing: rem(-0.25px);
    text-transform: uppercase;
  }
  
  .section {
    padding: var(--mantine-spacing-md);
    border-top: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
  }
  
  .icon {
    margin-right: rem(5px);
    color: light-dark(var(--mantine-color-gray-5), var(--mantine-color-dark-2));
  }