.inner {
    display: flex;
    justify-content: space-between;
    padding-top: calc(var(--mantine-spacing-xl) * 0);
    padding-bottom: calc(var(--mantine-spacing-xl) * 2);
  }
  
  .content {
    max-width: rem(480px);
    
  
    @media (max-width: mantine-breakpoint-md) {
      max-width: 100%;
      margin-right: 0;
    }
  }
  
  .title {
    color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
    font-family:
      Greycliff CF,
      var(--mantine-font-family);
    font-size: 30px;
    line-height: 1.2;
    font-weight: 900;
  
    @media (max-width: mantine-breakpoint-xs) {
      font-size: rem(28px);
    }
  }
  
  .control {
    @media (max-width: mantine-breakpoint-xs) {
      flex: 1;
    }
  }
  
  .image {
    width: 350px ;
    height: 350px ;
  
    @media (max-width: mantine-breakpoint-md) {
      display: none;
    }
  }
  
  .highlight {
    position: relative;
    background-color: var(--mantine-color-orange-light);
    border-radius: var(--mantine-radius-sm);
    padding: rem(4px) rem(12px);
  }