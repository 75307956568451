@tailwind base;
@tailwind components;
@tailwind utilities;



@layer components {
    .search-bar {
        @apply w-0 group-hover:w-[300px] transition-all duration-300 rounded-full group-hover:border group-hover:border-gray-500 px-3 py-1 focus:outline-none  dark:border-gray-800; 
    }
}

*{
    margin: 0;
    padding: 0;
    
}



@layer base{
    body{
        @apply bg-[#fefbf9] h-[80vh] ;
    }
}


  